import axios from "axios";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import FadeInSection from "./animateFadeIn";
import "./style.scss";

import ShareFacebookComponent from "./ShareFacebookComponent";
import ShareLinkedinComponent from "./ShareLinkedinComponent";
import ShareTwitterComponent from "./ShareTwitterComponent";
// import MetaTagComponent from './MetaTagComponent';

function ShareThinkInfo() {
  const [data, setData] = useState([]);
  const { slug } = useParams();
  let params = useParams();
  const getWork = (slugUrl) => {
    const selectLang = params.lang;
    const url = `https://www.suffix.works/api-v2/think-info/${selectLang}?slug=${slugUrl}`;
    const config = {
      headers: {
        Authorization: "Basic c3VmZml4OnN1ZmZpeDIwMjEq",
      },
    };
    return axios
      .get(url, config)
      .then((res) => {
        // console.log(res)
        setData(res.data);
      })
      .catch((err) => console.log(err));
    // return fetch(url)
    //     .then((res) => res.json())
    //     .then((d) => setData(d))
  };

  useEffect(() => {
    getWork(slug);
  }, [getWork, slug]);

  const setShareUrl = () => {
    let url = `${window.location.origin.toString()}/Thoughts/${slug}/${
      params.lang
    }`;
    return url;
  };

  return (
    <section className="sectionTeamWorksInfo sectionShareThinkInfo">
      <div className="wrapPage">
        {/* <MetaTagComponent title={data.title} description={data.description} url={setShareUrl()} imageUrl={data?.image} imageAlt={data.title} /> */}
        <FadeInSection>
          <Container>
            <Row>
              <Col>
                <h3>Share</h3>
                <div className="wrapBtnShareInfoThink">
                  <ShareLinkedinComponent
                    url={setShareUrl()}
                    title={data.title}
                    image={data?.image}
                  />
                  <ShareTwitterComponent
                    url={setShareUrl()}
                    title={data.title}
                    image={data?.image}
                  />
                  <ShareFacebookComponent
                    url={setShareUrl()}
                    title={data.title}
                    image={data?.image}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </FadeInSection>
      </div>
    </section>
  );
}

export default ShareThinkInfo;
