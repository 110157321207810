import './App.scss';
import InfoServices from './components/InfoServices.js';
import AppHeader from './components/AppHeader';


function PageServices() {                
  return (
    <div>
        <AppHeader />
         <InfoServices />
    </div>
  );
}

export default PageServices;
