import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppHeader from './components/AppHeader.js';

function PageProject() {
  return (
    <div>
      <AppHeader />
    </div>
  );
}

export default PageProject;